import React from "react";
import "./team.style.scss";
export default class TeamPage extends React.Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    
    return (
      <div  className="page-container">
      <h1>Team ist in bearbeitung ...</h1>
      </div>
    );
  }
}
