import React from "react";
import ReactPlayer from 'react-player';
import "./responsive-video.style.scss";

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import videoThumbnail from "../../assets/image/image-video-thumbnail.PNG" 

const ResponsivePlayer = ({url}) => {
 
  return (
         <div className="player-wrapper">
          <ReactPlayer
            url={url}
            className="react-player"
            width="100%"
            height="100%"
             playing
            playIcon={<PlayCircleFilledWhiteIcon className="button-play"/>}
             light={videoThumbnail}
            controls={true}

          /> 
    </div>
  )

}
export default ResponsivePlayer;