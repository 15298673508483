import React from "react";

export default class BlogPage extends React.Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    
    return (
      <div className="page-container">
      <h1>Blog ist in bearbeitung ...</h1>
      </div>
    );
  }
}
