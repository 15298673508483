import React from 'react'
import Grid from '@material-ui/core/Grid'
import './footer.style.scss'

const Footer = () => (
    <footer className="footer">
        <div className="footer-copyright">
            © 2019-2021 Plycoco International Ltd. |{' '}
            <a href="https://www.plycoco.de/de/impressum/">Impressum</a> |{' '}
            <a href="https://www.plycoco.de/de/datenschutz/">Datenschutz</a>
        </div>
    </footer>
)

export default Footer
