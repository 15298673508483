import React from 'react'
import Grid from '@material-ui/core/Grid'
import ResponsivePlayer from '../responsive-video/responsive-video.component'
import VideoPlycoco from '../../assets/video/Plycoco.mp4'
import './directory.style.scss'

const imageDate = {
  sections: [
    {
      id: 1,
      title: 'privatperson',
      imageUrl: require('../../assets/image/thumbnails/Frau-links.jpg'),
      text:
        'Sie sind eine Privatperson und suchen privat medizinische Betreuung',
      link: 'https://www.plycoco.de/de/infos/privat',
    },
    {
      id: 2,
      title: 'Pflege',
      imageUrl: require('../../assets/image/thumbnails/Hebamme.jpg'),
      text:
        'Sie sind Pflegefachkraft, Hebamme, Physiotheropeut, Pflegeassistent oder Hauswirtschafter und möchten selbständig arbeiten?',
      link: 'https://www.plycoco.de/de/infos/pflegekraft',
    },
    {
      id: 3,
      title: 'Einrichtung',
      imageUrl: require('../../assets/image/thumbnails/Hospital.jpg'),
      text: 'Sie sind eine Einrichtung und suchen Ärzte und Pflegekräfte',
      link: 'https://www.plycoco.de/de/infos/einrichtung',
    },
    {
      id: 4,
      title: 'Arzt',
      imageUrl: require('../../assets/image/thumbnails/Frauen-rechts.jpg'),
      text: 'Sie sind Arzt und möchten selbständig arbeiten?',
      link: 'https://www.plycoco.de/de/infos/arzt',
    },
  ],
}

export default function Directory() {
  return (
    <div className="directory-container">
      <Grid className="first-section" container spacing={4}>
        <Grid className="video-container" item xs={12} lg={8}>
          <ResponsivePlayer url={VideoPlycoco} />
        </Grid>
        <Grid item xs={12} lg={4} className="imagen-position-map">
          <div className="first-section_right">
            <h2>Willkommen bei Plycoco International</h2>
            <p>
              Du willst dein allerbestes den Menschen geben, die dich brauchen?
              <br />
              Dann bist du hier genau richtig.
            </p>
          </div>
          <div className="map-image" />
        </Grid>
      </Grid>

      <div className="second-section">
        <h2>Plycoco vermittelt weltweit</h2>
        <p>
          Wir arbeiten weltweit zusammen mit medizinischen Fachkräften und
          Einrichtungen. Über 7,5 Milliarden Menschen benötigen eine
          Krankenschwester, einen Arzt oder eine Hebamme. <br />
          Ob große oder kleine Patienten, du bist gefragt.
        </p>
      </div>
      <Grid />
      <Grid container spacing={4}>
        {imageDate.sections.map(({ id, ...otherSectionProps }) => (
          <Grid className="contain-image" key={id} item xs={12} sm={6} md={3}>
            <a href={otherSectionProps.link}>
              <img
                className="image-border"
                src={otherSectionProps.imageUrl}
                alt={otherSectionProps.title}
              />

              <div className="mask-hover-image">
                <h2>{otherSectionProps.title}</h2>
                <p>{otherSectionProps.text}</p>
              </div>
            </a>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
