import React from 'react';
import './App.css';
import {Route,Switch} from 'react-router-dom';
import Header from './components/header/header.component';
import HomePage from './pages/homepage/homepage.component';
import PersonalPage from './pages/personal/personal.component';
import BlogPage from './pages/blog/blog.component';
import TeamPage from './pages/team/team.component';
import ContactPages from './pages/contact/contact.component';
import Footer from './components/footer/footer.component.jsx'


function App() {
  return (
    <div className="App">
     
        <Header />
       <Switch>
          <Route exact path='/' component={HomePage} />
          <Route path='/personalicher-bereich' component={PersonalPage} />
          <Route path='/blog' component={BlogPage} />
          <Route path='/ansprechpartner' component={TeamPage} />
          <Route path='/kontakt' component={ContactPages} />
       </Switch>
        <Footer />
    
    </div>
  );
}

export default App;
