import React from 'react'
import './header.style.scss'
import { PhoneEnabled, MailOutline } from '@material-ui/icons'
import Logo from '../../assets/image/Logo.png'
import { Link } from 'react-router-dom'
import CustomButton from '../../components/custom-button/custom-button.component'
import IconButton from '@material-ui/icons/Menu'
import CookieBot from 'react-cookiebot'

const FlagIconEN = require('../../assets/flag-icon/EN.svg')
const FlagIconDE = require('../../assets/flag-icon/DE.svg')
const FlagIconFR = require('../../assets/flag-icon/FR.svg')
const FlagIconES = require('../../assets/flag-icon/ES.svg')
const FlagIconRU = require('../../assets/flag-icon/RU.svg')
const FlagIconPT = require('../../assets/flag-icon/PT.svg')
//-->
const domainGroupId = 'd4ac38fb-4aa5-4838-9e45-f6ef9001096b'

function Header() {
  const [isToggled, setToggled] = React.useState(false)

  const handleClick = () => {
    setToggled(!isToggled)
    const bsMenuContact = document.querySelector('#nav')
    bsMenuContact.classList.toggle('aktiv')
  }

  return (
    <div className="header">
      <CookieBot domainGroupId={domainGroupId} />
      <div className="nav-logo-language-icon">
        <div className="language-icon">
          <img className="flag-icon" src={FlagIconEN} alt="" />
          <img className="flag-icon" src={FlagIconDE} alt="" />
          <img className="flag-icon" src={FlagIconFR} alt="" />
          <img className="flag-icon" src={FlagIconES} alt="" />
          <img className="flag-icon" src={FlagIconRU} alt="" />
          <img className="flag-icon" src={FlagIconPT} alt="" />
          <span class="tooltiptext">coming soon</span>
        </div>
        <Link className="logo-container" to="/">
          <img className="logo" src={Logo} alt="" />
        </Link>
      </div>
      <div className="menu">
        <div className="background-nav">
          <div id="nav" className="nav">
            <div className="nav-left">
              <Link className="option" to="/">
                {' '}
                Home{' '}
              </Link>
              <a
                className="option"
                href="https://www.plycoco.de/de/persoenlicher_bereich/"
              >
                Persönlicher Bereich
              </a>
              <a className="option" href="https://www.plycoco.de/de/vision/">
                Vision
              </a>
              <a
                className="option last-bord"
                href="https://www.plycoco.de/de/kontakt/"
              >
                Kontakt
              </a>
            </div>
            <div className="logo-icon">
              <a href="https://www.plycoco.de/de/persoenlicher_bereich/">
                <CustomButton>Login</CustomButton>
              </a>
            </div>
            <div className="nav-contact">
              <div className="item">
                <MailOutline />
                <small>
                  <a href="mailto:contact@plycoco.com">contact@plycoco.com</a>
                </small>
              </div>
              <div className="item">
                <PhoneEnabled />
                <small>
                  <a href="tel:+35724022576">+357 24022576</a>
                </small>
              </div>
            </div>
            <div className="nav-contact">
              <div className="item">
                <PhoneEnabled />{' '}
                <span className="hotline">
                  <a href="tel:+49 30 644 99 444">
                    {' '}
                    +49 30 644 99 444
                    <div>deutschsprachige HOTLINE</div>
                  </a>
                </span>
              </div>
            </div>
          </div>

          <IconButton
            className="menu-icon"
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  )
}

export default Header
